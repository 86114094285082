import { Controller } from 'stimulus'; 

export default class extends Controller {
  static targets = ['toaster']

  connect() {
    setTimeout(function() {
      this.remove()
    }.bind(this), 4*1000)
  }

  remove() {
    this.toasterTarget.remove()
  }
}
