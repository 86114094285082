import { Controller } from 'stimulus'; 

export default class extends Controller {
  static targets = ['template', 'target']

  add(event) {
    event.preventDefault()
    const day = event.currentTarget.dataset.day
    const target = this.targetTargets.find(el => el.dataset.day == day)
    const template = this.templateTargets.find(el => el.dataset.day == day)

    const content = template.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    target.insertAdjacentHTML('beforebegin', content)
  }

  remove(event) {
    event.preventDefault()

    const wrapper = event.target.closest('.time-range-wrapper')

    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'
      wrapper.querySelector("input[name*='_destroy']").value = 1
    }
  }
}
