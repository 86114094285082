import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['summary', 'details'];

  toggle(event) {
    event.preventDefault();

    const icon = this.element.querySelector('i');
    icon.classList.toggle('ph-caret-circle-down');
    icon.classList.toggle('ph-caret-circle-up');

    this.detailsTargets.forEach((detail) => {
      detail.classList.toggle('hidden');
    });
    this.summaryTargets.forEach((summary) => {
      summary.classList.toggle('hidden');
    });
  }
}
