import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['reason', 'other', 'language']

  static values = {
    articleName: String,
    articleId: String,
    clientName: String,
    itemClicked: String,
    notificationChoice: String,
    origin: String,
    page: String,
    programId: String,
    programName: String,
    readingId: String,
    reading_data: String,
    stepName: String,
  }

  track(event, properties = {}, track_if_not_logged_in = true) {
    const is_pwa =
      // For iOS
      window.navigator.standalone ||
      // For Android
      window.matchMedia('(display-mode: standalone)').matches ||
      false;

    const eventProperties = {
      ...properties,
      is_pwa
    }

    fetch('/tracking/events', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({ tracking_event: { event, properties: eventProperties, track_if_not_logged_in } })
    });
  }

  itemClicked(event) {
    const dataset = event.currentTarget.dataset
    this.track(dataset.trackingEventName, JSON.parse(dataset.trackingProperties))
  }

  homepageAppointmentClicked() {
    this.track('Homepage Appointment Card Clicked')
  }

  homepageCardClicked(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }
    this.track('Homepage Card Clicked', properties)
  }

  sessionConfirmed(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }
    this.track('Individual Session Confirmed', properties)
  }

  sessionAddedToCalendar() {
    this.track('Individual Session Added to Calendar')
  }

  pageViewed() {
    const properties = {
      page: this.pageValue,
      program_name: this.programNameValue,
      program_id: this.programIdValue,
      article_name: this.articleNameValue,
      article_id: this.articleIdValue
    }
    this.track('Page Viewed', properties)
  }

  menuClicked(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }
    this.track('Menu Clicked', properties)
  }

  pwaInstalled() {
    const properties = {
      page: this.pageValue
    }
    this.track('PWA Installed', properties)
  }

  themeToggled(event) {
    const itemState = event.target.checked

    if (itemState === undefined) return

    const properties = {
      page: this.stepNameValue,
      action_taken: itemState ? 'field_selected' : 'field_unselected'
    }
    this.track('Onboarding Progressed', properties)
  }

  accessManagement(event) {
    let type = event.currentTarget.dataset['type']
    if (type === 'import') {
      const tab = document.querySelector('[data-tabs-target-name]:not(.hidden)')
      if (tab) {
        type = `import_${tab.dataset['tabsTargetName']}`
      }
    }

    const properties = {
      item_clicked: type
    }
    this.track('Access Management Clicked', properties)
  }

  practitionerCardClicked(event) {
    const properties = {
      practitioner: event.currentTarget.dataset.type
    }
    this.track('Practitioner Card Clicked', properties)
  }

  signUp(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset.type,
    }
    this.track('User Sign Up', properties)
  }

  signIn(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }

    this.track('User Sign In', properties)
  }

  hrMaterialClicked(event) {
    const properties = {
      id: event.currentTarget.dataset['id'],
      name: event.currentTarget.dataset['name'],
      language: event.currentTarget.dataset['language'],
      type: event.currentTarget.dataset['type'],
      purpose: event.currentTarget.dataset['purpose']
    }
    this.track('HR Material Clicked', properties)
  }

  hrMenuClicked(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }
    this.track('HR Menu Clicked', properties)
  }

  mhtCallClicked(event) {
    const properties = {
      current_page_path: event.currentTarget.dataset.currentPagePath,
      risk_level: event.currentTarget.dataset.riskLevel
    }
    this.track('MHT Call Clicked', properties)
  }

  practitionerSearch(event) {
    const isCheckbox = event.currentTarget.type === "checkbox"
    const item_clicked_value = isCheckbox ? event.currentTarget.checked : event.currentTarget.value
    const properties = {
      item_clicked: event.currentTarget.dataset.type,
      item_clicked_value
    }

    this.track('Practitioner Search Clicked', properties)
  }

  notificationSetUp(event) {
    const properties = {
      notification_choice: event.currentTarget.dataset['type']
    }
    this.track('Notification Set Up', properties)
  }

  pratAppointmentCancelled(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type'],
      reason_for_cancellation: this.reasonTarget.value,
      other_reason: this.otherTarget.value
    }
    this.track('Prat Appointment Cancelled', properties)
  }

  pratBookingPageNavigated(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }
    this.track('Prat Booking Page Navigated', properties)
  }

  pratMobileMenuClicked(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }
    this.track('Prat Mobile Menu Clicked', properties)
  }

  pratSessionsManaged(event) {
    const properties = {
      action_taken: event.currentTarget.dataset['type']
    }
    this.track('Prat Sessions Managed', properties)
  }

   pratTableNavigated(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }
    this.track('Prat Table Navigated', properties)
  }

  pratTableSearch() {
    this.track('Prat User Search Clicked')
  }

  invitationPageViewed() {
    const properties = {
      client_name: this.clientNameValue
    }
    this.track('Invitation Page Viewed', properties)
  }

  invitationPageHelpNeeded() {
    const properties = {
      client_name: this.clientNameValue
    }
    this.track('Invitation Page Help Needed', properties)
  }

  accessKeyProcessed() {
    const properties = {
      client_name: this.clientNameValue
    }
    this.track('Access Key Processed', properties)
  }

  individualSessionConfirmation(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type'],
      language: this.languageTarget.value
    }
    this.track('Individual Session Confirmation', properties)
  }

  individualSessionBooking(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }
    this.track('Individual Session Booking', properties)
  }

  hrDashboardTabClicked(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }
    this.track('HR Dashboard Tab Clicked', properties)
  }

  seminarPageViewed() {
    const properties = {
      page: this.pageValue
    }
    this.track('Seminar Page Viewed', properties)
  }

  breatheLaunched(duration, audioEnabled) {
    const properties = {
      duration,
      audio: audioEnabled ? 'on' : 'off'
    }
    this.track('Breathing Session Launched', properties, false)
  }

  // Self-care related events
  // Self-care "Page Viewed" events
  selfcareHomePageViewed() {
    this.track('Self-Care Home Page Viewed')
  }
  // ---\Self-care "Page Viewed" events

  selfcareCategoryClicked(event) {
    const properties = {
      category_name: event.currentTarget.dataset['type']
    }
    this.track('Selfcare Category Clicked', properties)
  }

  selfcareContentPreferencedChanged(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }
    this.track('Selfcare Content Preferenced Modified', properties)
  }

  selfcareContentPreferencedModified(event) {
    const properties = {
      item_clicked: event.currentTarget.checked ? "topic_selected" : "topic_unselected"
    }
    this.track('Selfcare Content Preferenced Modified', properties)
  }

  selfcareSearchRestarted() {
    this.track('Selfcare Search Restarted')
  }

  selfcareSearchRun(event) {
    const searchTracking = event.currentTarget.querySelector('#search-tracking');
    const properties = {
      query: searchTracking.dataset.query,
      results_count: searchTracking.dataset.resultsCount,
    }
    this.track('Selfcare Search Run', properties)
  }

  selfcareThemeButton(event) {
    const properties = {
      item_clicked: event.currentTarget.dataset['type']
    }
    this.track('Selfcare Theme Button', properties)
  }
  // ---\Self-care related events
}
