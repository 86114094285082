import {Controller} from "stimulus"

export default class extends Controller {
    static targets = ['tooltip', 'anchor'];

    get options() {
        return {
            xOffset: 0,
            yOffset: -35
        }
    };

    adjust_position(e) {
        if (window.innerWidth < (e.pageX + this.tooltipTarget.offsetWidth + this.options.xOffset)) {
            this.tooltipTarget.style.top = (e.pageY - this.options.yOffset) + "px";
            this.tooltipTarget.style.left = (e.pageX - this.tooltipTarget.offsetWidth - this.options.xOffset) + "px";
        } else {
            this.tooltipTarget.style.top = (e.pageY - this.options.yOffset) + "px";
            this.tooltipTarget.style.left = (e.pageX + this.options.xOffset) + "px";
        }
    }
};
