import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['zone', 'label', 'input'];
  static values = {
    defaultLabel: String
  }

  handleChange(event) {
    if (event.target.files.length == 0) {
      this.inputTarget.files = null;
      this.labelTarget.innerText = this.defaultLabelValue;
      return
    }
    const filename = event.target.files[0].name;
    this.labelTarget.innerText = filename;
    this.labelTarget.classList.add('font-semibold', 'text-sm');

    this.zoneTarget.classList.remove('border-yellow-base');
    this.zoneTarget.classList.add('border-gray-400');

    const icon = this.element.querySelector('.material-icons');
    icon.classList.add('animate-pulse-slow');
  }

  handleDrag(event) {
    // Prevent file from being opened
    event.preventDefault();

    if (event.type == 'dragover') {
      this.zoneTarget.classList.remove('border', 'border-gray-400');
      this.zoneTarget.classList.add('border-2', 'border-yellow-base');
    }

    if (event.type == 'dragleave') {
      this.zoneTarget.classList.remove('border-2', 'border-yellow-base');
      this.zoneTarget.classList.add('border', 'border-gray-400');
    }
  }

  handleDrop(event) {
    // Prevent file from being opened
    event.preventDefault();

    // Use DataTransferItemList interface to access the file
    // We do not wish to handle multiple file drops
    const item = [...event.dataTransfer.items][0];
    const accepted_types = this.inputTarget.accept;

    // If dropped item is an accepted file, transfer it
    if (item.kind === 'file' && accepted_types.includes(item.type)) {
      const file = item.getAsFile();
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);

      this.inputTarget.files = dataTransfer.files;
      this.inputTarget.dispatchEvent(new CustomEvent('change'));
    }
  }
}
