import { Controller } from 'stimulus';
import { debounce } from 'debounce';

export default class extends Controller {
  static targets = ['input', 'submit'];

  initialize() {
    const search = () => {
      const previousSearch = window.location.href;
      const newSearch = `${this.inputTarget.name}=${this.inputTarget.value}`;

      if (!newSearch.match(new RegExp(previousSearch))) {
        this.submitTarget.click();
      }
    }
    this.hideOnSearch()
    this.debouncedSearch = debounce(search, 300)
  }

  // Hide parts of page when searching
  hideOnSearch() {
    document.querySelectorAll('.hide-on-search').forEach((element) => {
      element.classList.toggle('hidden', !!this.inputTarget.value);
    });
  }

  runSearch(event) {
    if (event.data === undefined) {
      this.inputTarget.value = '';
      this.submitTarget.click();
      this.inputTarget.focus();
    }

    this.hideOnSearch();
    this.debouncedSearch();
  }
}
