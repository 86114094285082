import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['menu']

  toggle(e) {
    e.preventDefault()

    const menuOpened = e.target.innerText == 'close'
    e.target.innerText = menuOpened ? 'menu' : 'close'

    this.menuTarget.classList.toggle('hidden')
  }
}
