import { Controller } from 'stimulus'; 

export default class extends Controller {
  static targets = ['choice', 'field']
  static classes = ['selected']

  connect() {
    if(this.fieldTarget.value != '') {
      const choice = this.choiceTargets.find(c => c.dataset.id == this.fieldTarget.value)
      this.selectChoice(choice)
    }  
  }

  select(e) {
    e.preventDefault()

    this.selectChoice(e.target)
  }

  selectChoice(choice) {
    // Disable all the choices
    this.choiceTargets.forEach(choice => choice.classList.remove('radio-button--selected'))
    
    // Enable the choice
    choice.classList.add('radio-button--selected')

    // Set the choice in the hidden field
    const choiceId = choice.dataset.id
    if (this.fieldTarget.value != choiceId) {
      this.fieldTarget.value = choiceId
      if (this.element.dataset.autosubmit) {
        document.getElementById(this.element.dataset.autosubmit).submit();
      }
    }
  }
}
