import { parse } from 'postcss';
import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['input', 'increment', 'decrement'] 

  connect () {
    this.resetState()
  }

  increment(e) {
    e.preventDefault();
    this.inputTarget.value = this.inputValue + 1;
    this.resetState()
  }

  decrement(e) {
    e.preventDefault();
    this.inputTarget.value = this.inputValue - 1;
    this.resetState()
  }

  resetState() {
    this.decrementTarget.removeAttribute('disabled');
    this.incrementTarget.removeAttribute('disabled');

    if (this.inputValue >= this.max) {
      this.incrementTarget.setAttribute('disabled', true);
      this.inputTarget.value = this.max;
    }
    
    if (this.inputValue <= this.min) {
      this.decrementTarget.setAttribute('disabled', true);
      this.inputTarget.value = this.min;
    }
  }

  // private

  get inputValue() {
    return parseInt(this.inputTarget.value, 10) || 0;
  }

  get min() {
    return parseInt(this.inputTarget.getAttribute('min'), 10);
  }

  get max() {
    return parseInt(this.inputTarget.getAttribute('max'), 10);
  }
}
