import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = [
      'customOption',
      'selectIcon',
      'selectInput',
      'textInput',
      'textInputWrapper',
    ]

    toggleInputs(e) {
      if (this.hasCustomOptionTarget) {
        if(this.selectInputTarget.value != this.customOptionTarget.value) return

        this.textInputWrapperTarget.classList.toggle('hidden')
        this.selectInputTarget.classList.toggle('hidden')

        if (this.hasSelectIconTarget) {
          this.selectIconTarget.classList.toggle('hidden')
        }

        if (!this.selectInputTarget.classList.contains('hidden')) {
            this.selectInputTarget.value = ''
            this.selectInputTarget.selectedIndex = 0
        } else {
            this.textInputTarget.value = ''
            this.textInputTarget.focus()
        }
      }
    }

    setCustomOption(e) {
        this.customOptionTarget.value = this.textInputTarget.value
        this.selectInputTarget.value = this.textInputTarget.value
    }
}
