import { Controller } from 'stimulus';

export default class extends Controller {
    static targets = ["scroll"]

    connect () {
        this.scrollToLeft();
        this.scrollToRight();
    }

    scrollToLeft() {
        this.scrollTargets.forEach((element) => {
            element.scrollTo({
                left: element.scrollLeft + 250,
                behavior: 'smooth'
            });
        })
    }

    scrollToRight() {
        this.scrollTargets.forEach((element) => {
            element.scrollTo({
                left: element.scrollLeft - 250,
                behavior: 'smooth'
            });
        })
    }
}
