
import { application } from "./controllers/application"

import AudioPlayerController from "../components/audio_player_controller"
application.register("audio-player", AudioPlayerController)

import DropZoneInputController from "../components/drop_zone_input_controller"
application.register("drop-zone-input", DropZoneInputController)

import tooltipController from "../components/tooltip_controller"
application.register("tooltip", tooltipController)

import carouselController from "../components/carousel_controller"
application.register("carousel", carouselController)
