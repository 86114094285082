import { Controller } from 'stimulus';


export default class extends Controller {
  static targets = ['form', 'recommendationButton', 'recommendationButtonDisabled', 'questionNumber', 'inputEN', 'inputFR', 'emptyInput']
  static values = { questionCount: String }

  answer() {
    this.formTarget.submit()

    if (this.questionNumberTarget.value === this.questionCountValue) {
      this.recommendationButtonDisabledTarget.classList.add('hidden')
      this.recommendationButtonTarget.classList.remove('hidden')
    }
  }

  end() {
    this.formTarget.submit()
  }

  setDescription() {    
    const radioButtons = document.getElementsByName("client[launch_language]");

    radioButtons.forEach((element) => {
      if (element.checked) { 
        const locale = element.value

        if (locale === 'en') {
          this.inputENTarget.classList.remove("hidden")
          this.inputFRTarget.classList.add("hidden")
          this.emptyInputTarget.classList.add("hidden")
        }
        
        if (locale === 'fr') {
          this.inputFRTarget.classList.remove("hidden")
          this.inputENTarget.classList.add("hidden")
          this.emptyInputTarget.classList.add("hidden")
        }
      }
    })
  }
}
