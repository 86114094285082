import { Controller } from 'stimulus';

export default class extends Controller {
  openPopup() {
    const calendlyLink = document.querySelector('a[onclick^="Calendly.initPopupWidget"]')

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const openCalendly = urlParams.get('calendly');

    if (calendlyLink && openCalendly === 'true') {
      calendlyLink.click();
    }
  }
}
