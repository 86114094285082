import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['readingForm', 'aasmEvent', 'prismicArticle', 'progressBar'];

  connect() {
    if (this.hasReadingFormTarget) {
      this.trackScrollProgression()
      this.addScrollListener()
    }
  }

  addScrollListener() {
    window.addEventListener('scroll', () => {
      this.trackScrollProgression();
    });
  }

  trackScrollProgression() {
    const { scrolled, noScrollPage, bottomScrolled, middleScrolled, middleSubmitted, endSubmitted } = this.setScrolleVariables();
    this.element.dataset.noScrollPage = noScrollPage;
    this.displayProgressBar(scrolled);
    if (bottomScrolled && !endSubmitted) {
      this.updateReading('finish');
    }
    else if (middleScrolled && !middleSubmitted) {
      this.updateReading('start');
    }
  }

  setScrolleVariables() {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    let BOTTOM = 95
    let MID_PAGE = 50
    const scrolled = (winScroll / height) * 100;
    const noScrollPage = height === 0;
    const bottomScrolled = scrolled >= BOTTOM || height === 0;
    const middleScrolled = scrolled >= MID_PAGE;
    const middleSubmitted = this.readingFormTarget.dataset.articleMiddleSubmittedValue === 'true';
    const endSubmitted = this.readingFormTarget.dataset.articleEndSubmittedValue === 'true';

    return {
      scrolled,
      noScrollPage,
      bottomScrolled,
      middleScrolled,
      middleSubmitted,
      endSubmitted
    }
  }

  updateReading(event) {
    this.submitForm(event);
  }

  displayProgressBar(scroll) {
    this.progressBarTarget.style.width = scroll + "%";
  }

  submitForm(event) {
    this.aasmEventTarget.value = event;
    const form = this.readingFormTarget;
    if (event === 'start') {
      form.dataset.articleMiddleSubmittedValue = true;
    }
    if (event === 'finish') {
      form.dataset.articleMiddleSubmittedValue = true;
      form.dataset.articleEndSubmittedValue = true;
    }
    Rails.fire(form, 'submit');
  }
}
