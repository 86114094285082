import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['cancelledReason', 'cancelledReasonDetails', 'submitBtn', 'creditNoShowAlert']

  connect() {
    this.setSubmitButtonDisabled();
  }

  setSubmitButtonDisabled() {
    const submitBtn = this.submitBtnTarget;
    submitBtn.disabled = !(!!this.cancelledReasonTarget.value && !!this.cancelledReasonDetailsTarget.value)
  }

  toggleCreditNoShowAlertVisibility() {
    if (this.cancelledReasonTarget.value === 'member_no_show') {
      this.creditNoShowAlertTarget.classList.remove('hidden');
    } else {
      this.creditNoShowAlertTarget.classList.add('hidden');
    }
  }
}
