import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['form', 'businessUnit', 'office', 'position'];
  static values = {
    userSegPositionsUnset: Boolean,
    userSegOfficesUnset: Boolean,
    userSegBusinessUnitsUnset: Boolean,
    userSegmentationEnabled: Boolean
  }

  checkMissingDataBeforeSubmit(e) {
    if ((!this.userSegPositionsUnsetValue && this.hasEmptyPositionFields())
          || (this.userSegmentationEnabledValue &&
            (!this.userSegOfficesUnsetValue && this.hasEmptyOfficeFields())
                || (!this.userSegBusinessUnitsUnsetValue && this.hasEmptyBusinessUnitFields()))) {
      const modalController = this.application.getControllerForElementAndIdentifier(this.element, 'extended-modal')
      modalController.open(e)
    } else {
      this.submitForm(e)
    }
  }

  submitForm(event) {
    event.currentTarget.setAttribute('disabled', 'disabled')
    this.formTarget.submit()
  }

  hasEmptyPositionFields() {
    return !this.hasPositionTarget ||
      this.positionTargets.some(positionTarget => positionTarget.value === "" || !positionTarget.value || positionTarget.value === "na")
  }
  hasEmptyOfficeFields() {
    return !this.hasOfficeTarget || this.officeTargets.some(officeTarget => officeTarget.value === "" || !officeTarget.value)
  }

  hasEmptyBusinessUnitFields() {
    return !this.hasBusinessUnitTarget ||
      this.businessUnitTargets.some(businessUnitTarget => businessUnitTarget.value === "" || !businessUnitTarget.value)
  }
}
