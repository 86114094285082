import { Controller } from 'stimulus'; 
import jstz from 'jstz'

export default class extends Controller {
  static targets = ['field']

  connect() {
    const hash = window.location.hash
    if(hash == '#login') {
      const timezone = jstz.determine()
      const body = JSON.stringify({ user: { time_zone: timezone.name() }})
      const csrfToken = document.head.querySelector("meta[name='csrf-token']").content
      const headers = {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      }
      const request = new Request('/profile', { method: 'PATCH', body: body, headers: headers })
      fetch(request)
    }
  }
}
