import { Controller } from 'stimulus';
import { debounce } from 'debounce';
export default class extends Controller {
  static targets = [
    'search',
    'label',
    'clearSearch',
    'keywords',
    'location',
    'latitude',
    'longitude',
    'title',
    'speciality',
    'submit',
    'remote',
    'language'
  ];

  static values = { 'locale': String }

  connect() {
    const currentLocale = this.localeValue;
    this.autocomplete = new google.maps.places.Autocomplete(this.locationTarget);

    if (currentLocale === 'fr') {
      this.autocomplete.setComponentRestrictions({ country: currentLocale })
    }

    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      this.placeChanged();
    })

    // slow / fast technique for autocomplete
    /*
     fast updates results quickly, slow makes sure we display the right results in the end (eventual consistency)
     -> it is because turbo frame can receive turbo-frame out of order ("el" search can arrive after "eloi")
     -> there are various ways to deal with this properly at the request level, but turbo does not give any way to hook
     so we have to play with timer
    */
    this.inputChangedDebouncedSlow = debounce(() => {
      const currentInputValue = this.keywordsTarget.value;
      if (!window.location.href.match(new RegExp(`keywords%5D=${currentInputValue}`))) {
        this.inputChanged();
      }
    }, 1000)
    this.inputChangedDebouncedFast = debounce(() => {
      this.inputChanged();
    }, 200)

    // need to defer data-action after google place autocomplete instanciate, otherwise google place fail to start
    this.locationTarget.setAttribute('data-action', 'click->tracking#practitionerSearch input->practitioner-search#locationChanged')
  }

  languageTargetConnected(target) {
    if (target.value && this.submitTarget) {
      this.submitTarget.click();
    }
  }

  keywordsChanged() {
    this.inputChangedDebouncedSlow();
    this.inputChangedDebouncedFast();
  }

  inputChanged() {
    this.renderClearSearch();
    this.submitTarget.click();
    if (window.scrollY < 100 && !this.scrolled) {
      this.element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      this.scrolled = true;
    }
  }

  renderClearSearch() {
    const shouldDisplaySearchButton = this.searchTargets.filter((target) => target.value != "" && target.name !== 'remote_sessions').length > 0;
    if (shouldDisplaySearchButton) {
      this.clearSearchTarget.classList.remove('hidden');
    } else {
      this.clearSearchTarget.classList.add('hidden');
    }
  }

  placeChanged() {
    try {
      const { lat, lng } = this.autocomplete.getPlace().geometry.location;
      this.latitudeTarget.value = lat();
      this.longitudeTarget.value = lng();
    } catch (e) {
      console.log('Failed to retrieve location', e)
    }

    this.inputChanged()
  }

  locationChanged() {
    this.latitudeTarget.value = '';
    this.longitudeTarget.value = '';
    if (!this.locationTarget.value) {
      this.inputChanged()
    }
  }

  cancelSearch() {
    this.clearAllInputs();
    this.inputChanged();
  }

  searchForVisio() {
    this.clearAllInputs();
    this.remoteTarget.checked = true;
    this.inputChanged();
  }

  clearAllInputs() {
    this.searchTargets.forEach((target) => {
      target.value = ""
    });
  }
}
