import { Controller } from 'stimulus'; 

export default class extends Controller {

  connect() {      
    this.setupAnimation();
  }

  disconnect() {
    this.element.removeEventListener("turbo:before-frame-render", this.frameListener)
  }

  setupAnimation() {
    this.frameListener = (event) => {
      event.preventDefault()
      this.element.addEventListener('animationend', () => {
        this.element.classList.remove('animate-[fadeout_0.3s_ease-in-out]')
        this.element.classList.add('animate-[fadein_0.3s_ease-in-out]')
        event.detail.resume()
      })
      this.element.classList.remove('animate-[fadein_0.3s_ease-in-out]')
      this.element.classList.add('animate-[fadeout_0.3s_ease-in-out]')
    }

    this.element.addEventListener("turbo:before-frame-render", this.frameListener)
  }
}
