import { Controller } from 'stimulus';


export default class extends Controller {
    static values = {
        index: String
    }
    removeEmailRule(e) {
        const currentEmailRule = e.target.closest(`#email_rule_details_${this.indexValue}`)
        currentEmailRule.parentNode.removeChild(currentEmailRule)
    }
}
