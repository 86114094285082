import { Controller } from 'stimulus';

export default class extends Controller {
  async connect() {
    if('serviceWorker' in navigator) {
      try {
        const registration = await navigator.serviceWorker.register('/sw.js')
        console.log('ServiceWorker registered with scope: ', registration.scope);
      } catch(err) {
        console.log('Service worker registration failed: ', err);
      }
    }
  }
}
