import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['reminderBanner']
  static values = { identifier: String }

  hide() {
    this.reminderBannerTarget.classList.add('hidden')
  }
}
