import { Modal } from "tailwindcss-stimulus-components"

export default class Drawer extends Modal {
  static values = {
    isOpen: Boolean,
  }

  connect() {
    super.connect();
    if (this.isOpenValue) {
      this.element.dispatchEvent(new CustomEvent('open'));
    }
  }
}
