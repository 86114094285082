import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'checkbox', 'submitBtn']

  connect() {
    this.setSubmitButtonDisabled()
  }

  setSubmitButtonDisabled() {
    if (this.hasSubmitBtnTarget) {
      const submitBtn = this.submitBtnTarget

      submitBtn.disabled = !this.checkboxTarget.checked
    }
  }

  submit() {
    this.formTarget.requestSubmit()
  }
}
