import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['form', 'submitBtn']

  answerSelected(event) {
    const inputType = event.target.type
    const button = this.submitBtnTarget.querySelector('button');
    if (inputType === "checkbox" || inputType === "textarea") {
      const numberSelectedInput = this.formTarget.querySelectorAll('input[type="checkbox"]:checked').length;
      const lengthTextInput = this.formTarget.querySelector('textarea')?.value?.length;

      if (numberSelectedInput > 0 || lengthTextInput > 0) {
        this.enableButton(button);
      } else {
        this.disableButton(button);
      }
    } else if (!this.alreadySubmitted) {
      this.enableButton(button);
      button.click();
      this.disableButton(button);
      this.alreadySubmitted = true
    }
  }

  disableButton(button) {
    button.disabled = true;
  }

  enableButton(button) {
    button.disabled = false;
  }
}
