import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['player', 'playIcon', 'pauseIcon', 'loopIcon', 'currentTime', 'remainingTime', 'progressBarContainer', 'progressBar']
  static values = {
    totalTime: Number,
    mediaTitle: String,
    mediaArtist: String,
    mediaAlbum: String,
    mediaArtwork: String
  }

  connect() {
    this.setMediaSessionData();
    this.movingCursorPosition = false
  }

  play() {
    const player = this.playerTarget
    if (player.paused || player.ended) {
      player.play();
    } else if (!player.paused && !player.ended) {
      player.pause();
    }
  }

  playerDuration() {
    return Math.round(this.playerTarget.duration)
  }

  setDuration() {
    this.totalTime = this.playerDuration()
    this.remainingTimeTarget.innerText = `-${this.secondToString(this.playerDuration())}`
  }

  updateCurrentTime() {
    const player = this.playerTarget
    const progress = (Math.round(player.currentTime) / this.playerDuration()) * 100
    if (!this.movingCursorPosition) {
      this.setProgressBarCursorPosition(progress)
    }
  }

  forward() {
    this.changeCurrentTime(15)
  }

  back() {
    this.changeCurrentTime(-15)
  }

  changeCurrentTime(seconds) {
    const player = this.playerTarget
    if (player.ended && seconds < 0) {
      this.showPlayButton()
    }
    player.currentTime = player.currentTime + seconds
    try {
      window.navigator.vibrate(5)
    } catch { }
  }

  secondToString(seconds) {
    const date = new Date(0)
    date.setSeconds(Math.round(seconds))
    const timeString = date.toISOString().substr(14, 5)
    return timeString
  }

  showPauseButton() {
    this.playIconTarget.classList.add('hidden')
    this.loopIconTarget.classList.add('hidden')
    this.pauseIconTarget.classList.remove('hidden')
  }

  showPlayButton() {
    this.playIconTarget.classList.remove('hidden')
    this.loopIconTarget.classList.add('hidden')
    this.pauseIconTarget.classList.add('hidden')
  }

  showLoopButton() {
    this.pauseIconTarget.classList.add('hidden')
    this.playIconTarget.classList.add('hidden')
    this.loopIconTarget.classList.remove('hidden')
  }

  setMediaSessionData() {
    if ('mediaSession' in navigator) {
      navigator.mediaSession.metadata = new MediaMetadata({
        title: this.mediaTitleValue,
        artist: this.mediaArtistValue,
        album: this.mediaAlbumValue,
        artwork: [
          { src: this.mediaArtworkValue, type: 'image/png' }
        ]
      });
    }
  }

  setCursorPosition(event) {
    const progress = this.progressFromEventPosition(event)
    this.setProgressBarCursorPosition(progress)
    this.playerTarget.currentTime = this.currentTimeFromProgress(progress)
  }

  applyNewCursorPosition(event) {
    if ((event.type == "mousemove" && event.button != 0) || !this.movingCursorPosition) return
    const progress = this.progressFromEventPosition(event)
    this.setProgressBarCursorPosition(progress)
  }

  disableMovingCursorPosition(event) {
    if (this.movingCursorPosition) {
      const progress = this.progressFromEventPosition(event)
      this.playerTarget.currentTime = this.currentTimeFromProgress(progress)
    }
    this.movingCursorPosition = false
  }

  enableMovingCursorPosition() {
    this.movingCursorPosition = true
  }

  progressFromEventPosition(event) {
    const progressBarRect = this.progressBarContainerTarget.getBoundingClientRect()
    const pageX = (event.changedTouches && event.changedTouches[0] && event.changedTouches[0].pageX) || event.pageX
    if (pageX - progressBarRect.x >= progressBarRect.width) return 100
    if (pageX - progressBarRect.x <= 0) return 0
    return (pageX - progressBarRect.x) / progressBarRect.width * 100.0
  }

  currentTimeFromProgress(progress) {
    return progress * Math.round(this.playerDuration()) / 100
  }

  setProgressBarCursorPosition(progress) {
    const currentTime = progress * Math.round(this.playerDuration()) / 100
    this.currentTimeTarget.innerText = this.secondToString(currentTime)
    this.remainingTimeTarget.innerText = `-${this.secondToString(this.totalTime - currentTime)}`
    this.progressBarTarget.style.width = `${progress}%`
  }
}
