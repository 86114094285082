import { Controller } from 'stimulus'; 

export default class extends Controller {
  static targets = ['content']

  scroll(e) {
    e.preventDefault()

    const top = this.contentTarget.getBoundingClientRect().y
    window.scrollTo({ top: top, left: 0, behavior: 'smooth'})
  }
}

