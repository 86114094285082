import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['banner']
  static values = { identifier: String }

  connect() {
    const bannerStatus = localStorage.getItem('hide-hr-csv-banner')
    bannerStatus === 'Off' ? this.hide() : this.show()
  }

  hide() {
    this.bannerTarget.classList.add('hidden')
    this.element.hidden = true
    localStorage.setItem('hide-hr-csv-banner', 'Off')
  }

  show() {
    this.bannerTarget.classList.remove('hidden')
    this.element.parent.hidden = false
  }
}
