import { Controller } from 'stimulus'; 

export default class extends Controller {
  static targets = ['input', 'slot', 'submit']

  select(event) {
    const slot = event.target.dataset.slot

    this.slotTargets.forEach(slot => {
      slot.classList.add('opacity-40')
    })
    event.target.classList.remove('opacity-40')

    this.inputTarget.value = slot

    this.submitTargets.forEach(el => {
      el.disabled = false
      el.classList.remove('opacity-50')
    })
  }
}
