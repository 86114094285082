// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "@hotwired/turbo-rails"
import * as openpgp from 'openpgp/lightweight';
import "./controllers"
import "./components"

Rails.start()
ActiveStorage.start()

document.addEventListener('turbo:frame-missing', (event) => {
  event.preventDefault()
  event.detail.visit(event.detail.response)  
})
