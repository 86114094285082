import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['navbar', 'iconButton']
  static values = { previousTabType: String }

  connect() {
    if (this.hasIconButtonTarget) {
      this.resetActiveTab();
    }
  }

  resetActiveTab() {
    this.iconButtonTargets.forEach(tab => this.deactivateTab(tab.dataset.colorSwitchIconNameParam))
    const activeTab = this.iconButtonTargets.find(tab => tab.dataset.currentActive === 'true');
    this.activateTab(activeTab);
  }

  switchColor() {
    if (this.hasNavbarTarget) {
      const documentHeight = document.body.scrollHeight
      const currentScroll = window.scrollY + window.innerHeight
      const modifier = 50
  
      if (currentScroll + modifier > documentHeight) {
        // user has scrolled near page bottom
        this.navbarTarget.classList.remove('bg-white')
        this.navbarTarget.classList.add('bg-yellow-base')
      }
  
      if (currentScroll + modifier <= documentHeight) {
        // user has scrolled away from page bottom
        this.navbarTarget.classList.remove('bg-yellow-base')
        this.navbarTarget.classList.add('bg-white')
      }
    }
  }

  addBtnStyle(event) {
    this.iconButtonTargets.forEach(tab => this.deactivateTab(tab.dataset.colorSwitchIconNameParam))
    const newActivetab = this.iconButtonTargets.find(tab => tab.dataset.colorSwitchIconNameParam === event.params.iconName);
    this.activateTab(newActivetab)
  }


  activateTab(tab) {
    if (!tab) return

    const iconDiv = document.getElementById(`icon_${tab.dataset.colorSwitchIconNameParam}`);
    if (iconDiv) {
      iconDiv.classList.remove('material-icons-outlined')
      iconDiv.classList.add('material-icons')
    }

    tab.classList.remove('border-gray-200', 'text-gray-500')
    tab.classList.add('border-purple-400', 'text-purple-400')
  }

  reactivatedTab(typeName) {
    const oldTabsList = document.querySelectorAll(`[data-type='${typeName}']`);
    const lastOldTab = oldTabsList.item(oldTabsList.length - 1);
    this.activateTab(lastOldTab);
  }

  deactivateTab(tabName) {
    const iconDiv = document.getElementById(`icon_${tabName}`);
    const anchor = document.querySelector(`[data-color-switch-icon-name-param="${tabName}"]`)
    iconDiv.classList.remove('material-icons');
    iconDiv.classList.add('material-icons-outlined')
    anchor.classList.remove('border-purple-400', 'text-purple-400')
    anchor.classList.add('border-gray-200', 'text-gray-500')
  }
}
