import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['recommendedPrograms', 'programsToContinue', 'updateInterestsButton'];

  connect() {
    if (this.recommendedProgramsTargets.length > 0) {
      this.showRecommendedPrograms()
    } else {
      this.showProgramsToContinue()
    }
  }

  select(event) {
    event.preventDefault()

    if (event.target.value === 'continue') {
      this.showProgramsToContinue()
    } else {
      this.showRecommendedPrograms()
    }
  }

  showProgramsToContinue() {
    this.programsToContinueTargets.forEach((element) => {
      element.classList.remove("!hidden")
    })
    this.recommendedProgramsTargets.forEach((element) => {
      element.classList.add("!hidden")
    })
    this.updateInterestsButtonTargets.forEach((element) => {
      element.classList.add("invisible")
    })
  }

  showRecommendedPrograms() {
    this.programsToContinueTargets.forEach((element) => {
      element.classList.add("!hidden")
    })
    this.recommendedProgramsTargets.forEach((element) => {
      element.classList.remove("!hidden")
    })
    this.updateInterestsButtonTargets.forEach((element) => {
      element.classList.remove("invisible")
    })
  }
}
