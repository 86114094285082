import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toaster"]

  copy(event) {
    event.stopPropagation();
    event.preventDefault();
    this.writeToClipboard(this.textToCopy)
  }

  writeToClipboard(text) {
    const clipboardItem = new ClipboardItem({
      "text/html": new Blob([text], { type: "text/html" }),
      "text/plain": new Blob([text], { type: "text/plain" })
    });

    navigator.clipboard.write([clipboardItem]).then(() => {
      const toaster = this.toasterTarget.content.cloneNode(true)
      this.element.appendChild(toaster)
    }, () => {
      alert("Unable to write into the clipboard")
    })
  }

  get textToCopy() {
    var brRegex = /<br\s*[\/]?>/gi;
    return this.data.get("text-to-copy").replace(brRegex, "\r\n")
  }
}
