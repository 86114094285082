import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['expand', 'abbreviate', 'content']
  static values = { open: Boolean }

  connect() {

    if (this.openValue) {
      this.toggle()
      this.element.scrollIntoView()
    }
  }

  toggle(e) {
    e && e.preventDefault()

    this.expandTarget.classList.toggle('hidden')
    this.abbreviateTarget.classList.toggle('hidden')
    this.contentTarget.classList.toggle('hidden')
  }
}
