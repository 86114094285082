import { Controller } from 'stimulus';
import { debounce } from 'debounce';

export default class extends Controller {
  static targets = [
    'category',
    'clearSearch',
    'form',
    'query',
    'search',
    'theme'
  ]

  connect() {
    // slow / fast technique for autocomplete
    /*
     fast updates results quickly, slow makes sure we display the right results in the end (eventual consistency)
     -> it is because turbo frame can receive turbo-frame out of order ("el" search can arrive after "eloi")
     -> there are various ways to deal with this properly at the request level, but turbo does not give any way to hook
     so we have to play with timer
    */
    this.inputChangedDebouncedSlow = debounce(() => {
      const currentInputValue = this.queryTarget.value

      if (!window.location.href.match(new RegExp(`query=${currentInputValue}&`))) {
        this.inputChanged()
      }
    }, 1000)
    
    this.inputChangedDebouncedFast = debounce(() => {
      this.inputChanged()
    }, 200)
  }

  cancelSearch() {
    this.clearAllInputs()
    this.renderClearSearch()
    this.inputChanged()
  }

  clearAllInputs() {
    this.searchTargets.forEach((target) => { target.value = '' })
  }

  inputChanged() {
    this.renderClearSearch()
    this.formTarget.requestSubmit()
  }

  queryChanged() {
    this.inputChangedDebouncedSlow()
    this.inputChangedDebouncedFast()
  }

  renderClearSearch() {
    const shouldDisplayClearSearch = this.searchTargets.filter((target) => target.value != '').length > 0
    const classList = this.clearSearchTarget.classList

    shouldDisplayClearSearch ? classList.remove('hidden') : classList.add('hidden')
  }
}