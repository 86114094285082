import { Controller } from 'stimulus';
const canWakeLock = () => 'wakeLock' in navigator;

export default class extends Controller {
  static targets = [
    'blob1', 'blob2', 'blob3', 'blob4', 'blob5', 'blob6', 'blob7', 'blobs', 'delimiter',
    'navigation', 'title', 'footer',
    'duration', 'durationSelect', 'remoteButton',
    'careMessage', 'careMessageDuration', 'endMessage', 'inhaleMessage', 'exhaleMessage', 'waitingMessage',
    'currentTime', 'endTime', 'progressBar', 'timer',
    'audioOffButton', 'audioUpButton', 'audio2minPlayer', 'audio5minPlayer', 'audio7minPlayer', 'audioBackgroundPlayer'
  ]

  initialize() {
    this.actionOnTouchBinded = this.actionOnTouch.bind(this)
    this.wakelock = null
  }

  connect() {
    this.animationRunning = 'animation-running'
    this.animationPaused  = 'animation-paused'

    this.blobs = [
      this.blob1Target,
      this.blob2Target,
      this.blob3Target,
      this.blob4Target,
      this.blob5Target,
      this.blob6Target,
      this.blob7Target
    ]

    this.delays = [ '0s', '0.2s', '0.4s', '0.6s', '0.8s', '1s', '1.2s' ]

    this.dependentAnimations = [
      this.delimiterTarget,
      this.inhaleMessageTarget,
      this.exhaleMessageTarget,
    ].concat(this.blobs)

    this.audioPlayers = {
      2: this.audio2minPlayerTarget,
      5: this.audio5minPlayerTarget,
      7: this.audio7minPlayerTarget
    }

    this.audioEnabled = false
    this.introDuration = 5 // seconds

    this.initialState()
  }

  initialState() {
    this.currentTimeTarget.innerHTML = "00:00"
    this.totalTime = (5 * 60 + this.introDuration) * 1000 // by default
    this.timePlaying = 0

    this.currentState = 5
    this.isStarted = false

    this.setDefaultDuration()

    this.setButton('play')
    this.disappear(this.timerTarget) // Timer

    // Move button and timer under duration select.
    this.remoteButtonTarget.classList.remove('top-[-70px]')
    this.timerTarget.classList.remove('top-[-60px]')

    this.showInterfaceElements() // navigation and footer
    this.appear(this.titleTarget) // Take a moment
    this.disappear(this.waitingMessageTarget) // relax
    this.disappear(this.inhaleMessageTarget) // inhale & exhale
    this.disappear(this.exhaleMessageTarget)

    this.delimiterTarget.classList.add('delimiter-breath')
    this.disappear(this.endMessageTarget) // great work
    this.disappear(this.careMessageTarget) // You took x minutes…
    this.appear(this.durationSelectTarget) // 2min, 5min, 7min

    this.footerTarget.classList.remove('before:bg-transparent')
    this.footerTarget.classList.add('before:bg-purple-100')

    this.disappearBlobs()
    this.appearBlobs()
    this.blobs.forEach((blob) => this.resetAnimation(blob))

    this.dependentAnimations.forEach((elmt) => this.resetAnimation(elmt))

    this.playBackgroundAudio()
  }

  actionOnTouch() {
    // Move button and timer in place of duration select.
    this.remoteButtonTarget.classList.add('top-[-70px]')
    this.timerTarget.classList.add('top-[-60px]')

    if (this.tapStatus == '') {
      this.showInterfaceElements()
      this.tapStatus = 'tap'
    } else {
      this.hideInterfaceElements()
      this.tapStatus = ''
    }
  }

  cleanAllTransitionDelay(element) {
    element.style.transitionDelay = ''
  }

  appear(element, index) {
    if (index) { element.style.transitionDelay = this.delays[index] }
    element.classList.replace('opacity-0', 'opacity-100')
  }

  appearBlobs() {
    const limitedBlobs = this.blobs.slice(0, this.currentState)
    limitedBlobs.forEach((element, index) => this.appear(element, index))
  }

  disappearBlobs() {
    this.blobs.forEach((element, index) => this.disappear(element, index))
  }

  disappear(element) {
    // no gradual delay effect here
    element.classList.replace('opacity-100', 'opacity-0')
  }

  setDefaultDuration() {
    this.careMessageDurationTarget.innerHTML = this.currentState
    this.setDurationsAsInactive()
    this.setItemAsActive(this.currentState)
    this.addTime(this.currentState * 60, this.endTimeTarget) // duration in seconds
  }

  setDuration(e){
    e.preventDefault()

    const oldState = this.currentState

    this.currentState = e.target.dataset.duration

    this.setDurationsAsInactive()
    this.setItemAsActive(this.currentState)
    this.totalTime = (this.currentState * 60 + this.introDuration) * 1000

    this.blobs.forEach((element) => this.cleanAllTransitionDelay(element));

    this.addTime(this.currentState * 60, this.endTimeTarget) // duration in seconds

    this.careMessageDurationTarget.innerHTML = this.currentState

    if (oldState < this.currentState) {
      let n = oldState

      while (n < this.currentState) {
        this.appear(this.blobs[n], Math.max(0, n - oldState))
        n++
      }
    } else {
      let n = oldState - 1

      while (n >= this.currentState) {
        this.disappear(this.blobs[n])
        n--
      }
    }
  }

  setItemAsActive(position){
    const elmt = this.durationTargets.find(elmt => elmt.dataset.duration == position)

    const label = elmt.firstElementChild.firstElementChild
    label.classList.add('opacity-100')
    label.classList.remove('opacity-0')

    elmt.classList.remove('border-transparent', 'opacity-50')
    elmt.classList.add('border-purple-400', 'opacity-100')
  }

  setDurationsAsInactive() {
    this.durationTargets.forEach((elmt) => {
      const label = elmt.firstElementChild.firstElementChild
      label.classList.add('opacity-0')
      label.classList.remove('opacity-100')

      elmt.classList.add('border-transparent', 'opacity-50')
      elmt.classList.remove('border-purple-400', 'opacity-100')
    })
  }

  moveNavigationUp() {
    this.navigationTarget.classList.remove('top-0')
    this.navigationTarget.classList.add('top-[-50px]')
  }

  moveNavigationDown() {
    this.navigationTarget.classList.add('top-0')
    this.navigationTarget.classList.remove('top-[-50px]')
  }

  moveFooterUp() {
    this.footerTarget.classList.add('bottom-0')
    this.footerTarget.classList.remove('bottom-[-200px]')
  }

  moveFooterDown() {
    this.footerTarget.classList.remove('bottom-0')
    this.footerTarget.classList.add('bottom-[-200px]')
  }

  async play(event){
    event.stopPropagation()
    this.tapStatus = ''

    this.setButton('pause')

    await this.lockWakeState();

    // Different things to do if breathwork is play first time.
    if (this.isStarted == false) {
      this.hideInterfaceElements(true)
      this.start()
    } else {
      this.isPaused = false
      this.dependentAnimations.forEach((elmt) => this.playAnimation(elmt))
    }
    this.playMeditationAudio()
  }

  playMeditationAudio() {
    if (this.audioEnabled && !this.isPaused) {
      this.pauseBackgroundAudio()
      this.currentAudioPlayer().currentTime = this.timePlaying / 1000
      this.currentAudioPlayer().play()
    }
  }

  pause(event) {
    event.stopPropagation()
    this.isPaused = true
    this.setButton('play')
    this.releaseLockState()

    this.dependentAnimations.forEach((elmt) => this.pauseAnimation(elmt))
    if (this.audioEnabled) {
      this.pauseMeditationAudio()
    }
  }

  setButton(state) {
    const button = this.remoteButtonTarget
    const image = button.children[0]

    if (state == 'play') {
      button.dataset.action = "click->breathwork#play"
      image.src = button.dataset.playIcon
    } else {
      button.dataset.action = "click->breathwork#pause"
      image.src = button.dataset.pauseIcon
    }
  }

  start() {
    this.hideConfigurationElements()
    this.showAndHideWaitingMessage()

    setTimeout(() => {
      this.playAnimation(this.inhaleMessageTarget)
      this.playAnimation(this.exhaleMessageTarget)
      this.appear(this.timerTarget)
    }, 5000)

    this.blobs.forEach((elmt) => this.playAnimation(elmt))
    this.playAnimation(this.delimiterTarget)

    this.isPaused = false
    this.isStarted = true
    this.countDown()

    this.playMeditationAudio()

    document.addEventListener('click', this.actionOnTouchBinded)
    this.trackingController.breatheLaunched(this.currentState, this.audioEnabled)
  }

  countDown() {
    let remainingTime = this.totalTime;

    const countdown = () => {
      if(this.isPaused == false) {
        if(remainingTime > 0) {
          this.timePlaying = this.blob1Target.getAnimations()[0].currentTime

          const timePlayingInSeconds = this.timePlaying / 1000 - this.introDuration
          this.addTime(timePlayingInSeconds, this.currentTimeTarget)

          const progress = (timePlayingInSeconds * 100 ) / (this.currentState * 60)

          this.progressBarTarget.style.width = `${progress}%`

          remainingTime = this.totalTime - this.timePlaying

          this.blobs.forEach((elmt) => this.disappear(elmt))

          let remaningBlob = Math.min(Math.ceil(remainingTime / 1000 / 60), this.currentState)
          for(let i = 0; i < remaningBlob; i++) {
            this.appear(this.blobs[i], i)
          }
          requestAnimationFrame(countdown)
        } else {
          document.removeEventListener('click', this.actionOnTouchBinded)

          this.disappear(this.inhaleMessageTarget)
          this.disappear(this.exhaleMessageTarget)

          this.appear(this.endMessageTarget)
          this.appear(this.careMessageTarget)

          this.delimiterTarget.classList.remove('delimiter-breath')

          this.dependentAnimations.forEach((elmt) => this.resetAnimation(elmt))
          setTimeout(() => { // will be after 10s.
            this.releaseLockState()
            this.initialState(this)
          }, 10000)
        }
      } else {
        requestAnimationFrame(countdown)
      }
    }

    requestAnimationFrame(countdown)
  }

  hideConfigurationElements() {
    this.disappear(this.titleTarget) // Hide the title
    this.disappear(this.durationSelectTarget) // Hide the duration selector
  }

  showAndHideWaitingMessage() {
    this.appear(this.waitingMessageTarget)
    setTimeout(() => {
      this.disappear(this.waitingMessageTarget)
    }, 5000)
  }

  hideInterfaceElements(backgroundColor) {
    this.moveNavigationUp(this.navigationTarget)
    this.moveFooterDown(this.footerTarget)

    if (backgroundColor) {
      this.footerTarget.classList.add('before:bg-transparent')
      this.footerTarget.classList.remove('before:bg-purple-100')
    }
  }

  showInterfaceElements() {
    this.moveNavigationDown(this.navigationTarget)
    this.moveFooterUp(this.footerTarget)
  }

  pauseAnimation(element) {
    element.classList.replace(this.animationRunning, this.animationPaused)
  }

  resetAnimation(element) {
    this.pauseAnimation(element)
    element.style.animation = 'none'
    setTimeout(() => element.style.animation = '', 0)
  }

  playAnimation(element) {
    element.classList.replace(this.animationPaused, this.animationRunning)
  }

  addTime(seconds, placement) {
    const date = new Date(0)
    date.setSeconds(seconds)
    const timeString = date.toISOString().substr(14, 5)
    placement.innerText = timeString
  }

  toggleAudio() {
    this.audioEnabled = !this.audioEnabled
    if (this.audioEnabled) {
      this.audioOffButtonTarget.classList.add('hidden')
      this.audioUpButtonTarget.classList.remove('hidden')

      this.playMeditationAudio()
    } else {
      this.audioOffButtonTarget.classList.remove('hidden')
      this.audioUpButtonTarget.classList.add('hidden')

      this.pauseMeditationAudio()
    }
  }

  playBackgroundAudio() {
    this.stopAllMeditationAudioPlayers()
    if (this.audioEnabled) {
      this.audioBackgroundPlayerTarget.play()
    }
  }

  pauseMeditationAudio() {
    this.currentAudioPlayer().pause()
  }

  currentAudioPlayer() {
    if (!this.isStarted) {
      return this.audioBackgroundPlayerTarget
    }
    return this.audioPlayers[this.currentState]
  }

  stopAllMeditationAudioPlayers() {
    Object.values(this.audioPlayers).forEach(audioPlayer => {
      audioPlayer.pause()
      audioPlayer.currentTime = 0
    })
  }

  pauseBackgroundAudio() {
    this.audioBackgroundPlayerTarget.pause()
    this.audioBackgroundPlayerTarget.currentTime = 0
  }

  get trackingController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "tracking")
  }
  async lockWakeState() {
    if(!canWakeLock()) return;
    try {
      this.wakelock = await navigator.wakeLock.request('screen');
    } catch(e) {
      console.error('Failed to lock wake state with reason:', e.message);
    }
  }

  releaseLockState() {
    if (this.wakelock) {
      this.wakelock.release();
      this.wakelock = null;
    }
  }
}
