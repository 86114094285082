import { Controller } from 'stimulus'; 
export default class extends Controller {
  static targets = ['mobileNavbar']

  connect() {
    this.lastScrollTop = 0;
  }

  animateNavbar() {
    const newScrollTop = window.pageYOffset

    if (newScrollTop <= this.lastScrollTop || this.nearPageBottom(newScrollTop)){
      this.showNavbar()
    } else {
      this.hideNavbar()
    }
    this.lastScrollTop = newScrollTop <= 0 ? 0 : newScrollTop; // For Mobile or negative scrolling
  }

  showNavbar() {
    this.mobileNavbarTarget.classList.remove('translate-y-20')
  }

  hideNavbar() {
    this.mobileNavbarTarget.classList.add('translate-y-20')
  }

  nearPageBottom(scrollPosition) {
    const screenHeight = window.innerHeight;
    const pageLength = document.body.offsetHeight;
    const navbarOffset = this.mobileNavbarTarget.offsetHeight * 0.75;

    return (screenHeight + scrollPosition) >= (pageLength - navbarOffset)
  }
}
