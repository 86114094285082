import { Controller } from 'stimulus'; 

export default class extends Controller {
  static values = {
    frameId: String
  }

  connect() {      
    this.reloadLater();
  }

  reloadLater() {
    setTimeout(() => {
      const frameElement = this.element.closest(`#${this.frameIdValue}`)
      const location = frameElement.src || Turbo.navigator.location
      Turbo.visit(location, { frame: frameElement.id })
    }, 2000)
  }

}
