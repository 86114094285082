import { Controller } from 'stimulus';
import Rails from "@rails/ujs";

export default class extends Controller {
    static targets = ['readingForm', 'aasmEvent'];

    connect() {
      if (this.hasReadingFormTarget) {
        this.setReadingToVisited()
      }
    }

    setReadingToVisited() {
        const form = this.readingFormTarget;
        form.dataset.articleMiddleSubmittedValue = true;
        Rails.fire(form, 'submit');
    }
}
