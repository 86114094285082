import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'password',
    'meter',
    'tooSmall',
    'score0',
    'score1',
    'score2',
    'score3',
    'score4',
    'parent'
  ]

  checkPassword() {
    const res = window.zxcvbn(this.passwordTarget.value)
    const score = this.passwordTarget.value.length < 9 ? 0 : res.score
    this.meterTarget.className = `meter-${score}`
    this.tooSmallTarget.classList.add('hidden')
    this.score0Target.classList.add('hidden')
    this.score1Target.classList.add('hidden')
    this.score2Target.classList.add('hidden')
    this.score3Target.classList.add('hidden')
    this.score4Target.classList.add('hidden')

    if (score > 0) {
      this[`score${res.score}Target`].classList.remove('hidden')
    }
    else {
      this.tooSmallTarget.classList.remove('hidden')
    }
    this.parentTarget.classList.remove('hidden')
  }

  toggleVisibility(e) {
    e.preventDefault()
    const targetName = `${e.params.target}Target`
    const target = this[targetName]

    if (target.type === 'password') {
      target.type = 'text'
      e.target.innerText = 'visibility_off'
    } else {
      target.type = 'password'
      e.target.innerText = 'visibility'
    }
  }
}

