import { Modal } from "tailwindcss-stimulus-components"

export default class extends Modal {
  static values = { identifier: String }

  connect() {
    const popInStatus = localStorage.getItem(this.identifierValue)

    !popInStatus ? this.showPopIn() : this.hidePopIn()

    localStorage.setItem(this.identifierValue, 'off')
  }

  close(e) {
    e.preventDefault()
    this.hidePopIn()
  }

  open(e) {
    e.preventDefault()
    this.showPopIn()
  }

  hidePopIn() {
    this.containerTarget.classList.add('hidden')
  }

  showPopIn() {
    this.containerTarget.classList.remove('hidden')
  }
}
