import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['language', 'phone', 'email', 'submitBtn']

  connect() {
    this.setSubmitButtonDisabled();
  }

  setSubmitButtonDisabled() {
    const submitBtn = this.submitBtnTarget;
    const languageFilled = this.languageTargets.some(language => language.checked)
    const phoneFilled = !!this.phoneTarget.value
    const emailFilled = (!!this.emailTarget.value) && (/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(this.emailTarget.value))
    submitBtn.disabled = !(emailFilled && phoneFilled && languageFilled);
  }
}
